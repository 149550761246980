// 카테고리별
.segmented-control {
  overflow: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > li {
    margin: 0 24px 0 0;
  }

  .segmented-item {
    position: relative;
    display: inline-block;
    min-width: 30px;
    padding: 10px 0 7px 0;
    @include body3($gr02, 600);
    @include transition(color 0.3s, border 0.3s, background-color 0.3s);

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 3px;
      width: 100%;
      border-radius: 3px;
      background-color: transparent;
      @include transition(background-color 0.3s);
    }

    > .txt {
      white-space: nowrap;
    }

    &.selected {
      color: $gr00;

      &::after {
        background-color: $gr00;;
      }
    }
  }

  + .list-dapps {
    margin-top: 12px;
  }
}
