$primary-dark-purple: (#231d54) !default;
$primary-deep-purple: (#331e99) !default;
$primary-purple: (#5d44da) !default;
$primary-light-purple: (#8475ef) !default;
$primary-smooth-purple: (#bab0ff) !default;
$primary-pale-purple: (#f0eefc) !default;

$secondary-evergreen: (#00c092) !default;
$secondary-light-evergreen: (#7bdcc5) !default;
$secondary-green: (#5de53a) !default;
$secondary-light-green: (#a9ee99) !default;
$secondary-orange: (#ff6f26) !default;
$secondary-light-orange: (#ffb792) !default;
$secondary-pink: (#ff43d6) !default;
$secondary-light-pink: (#fa9de7) !default;
$secondary-blue: (#1ba1f2) !default;
$secondary-light-blue: (#88ccf5) !default;

$sub-warning: (#fd564c) !default;
$sub-notice: (#1bbef2) !default;

$gr06: (#f9f9fc) !default;
$gr05: (#f1f3f5) !default;
$gr04: (#e9ecef) !default;
$gr03: (#dee2e6) !default;
$gr02: (#868e96) !default;
$gr01: (#495057) !default;
$gr00: (#212529) !default;
$wt: (#ffffff) !default;
$bk: (#000000) !default;

$purple-gradient: (
  linear-gradient(106.58deg, #b760b0 3.16%, #825cf9 53.2%, #518eee 97.83%)
);

:root {
  --primary-dark-purple: $primary-dark-purple;
  --primary-deep-purple: $primary-deep-purple;
}
