@import './navigation-header';

body {
  width: 100vw;
  overflow-x: hidden;
  background: #fff;

  &.lock-scroll {
    overflow: hidden;
    max-height: 100vh;
  }
}

.layout-wrap {
  min-height: 100vh;
}

.layout-contents-container {
  padding: 0 16px 40px 16px;

  &.page-home {
    padding: 0 0 80px 0;
    overflow: hidden;

    .section-contents {
      &.popular {
        padding: 0 0 0 24px;
      }

      &.bookmark {
        padding: 0 24px;
      }

      &.recommend {
        padding: 0 24px;
      }

      &.category {
        padding: 0 20px;
      }
    }
  }

  .page-title {
    display: block;
    padding: 13px 0;
    @include font(24, 600, #000000, 34);
  }
}

.layout-spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}
