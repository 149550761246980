// category_dapp_container inner
.inner {
  padding: 20px 0px 0px 16px;
}

// category_dapp_container 시작
.category_dapp_container {
  position: relative;
  &::before {
    width: 91.111%;
    content: '';
    border-bottom: 1px solid $gr05;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  // category_dapp_list 시작
  .category_dapp_list {
    padding: 24px 16px 28px 0px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    scroll-snap-type: x mandatory; /* 달라붙는 스냅 속성 부여 */
    &::-webkit-scrollbar {
      display: none;
    }
    > .list_item {
      display: flex;
      flex-flow: column;
      margin-right: 16px;
      width: calc(100% - 32px);
      scroll-snap-align: start; /* 하위 요소의 정렬 방향 */
      > li {
        display: flex;
        margin-bottom: 8px;
        max-width: 308px;
        position: relative;
        &::before {
          width: 83.116%;
          content: '';
          border-bottom: 1px solid $gr05;
          position: absolute;
          left: 16.883%;
          right: 0;
          bottom: 0;
          margin: auto;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      > li:last-child {
        &::before {
          border: none;
        }
      }
    }
  }
}

.view_all_bt_wrap {
  text-align: center;
  .view_all_button {
    height: 48px;
    padding: 12px 20px;
    background: $primary-purple;
    margin: 40px auto 0;
    @include font(16, 400, $wt, 24);
    border-radius: 32px;
    img {
      vertical-align: middle;
      margin-left: 2px;
    }
  }
}

// featured rotation animation
.featured_fade_in {
  visibility: visible;
  opacity: 1;
  transition: 2s;
}

.featured_fade_out {
  visibility: hidden;
  opacity: 0;
}
