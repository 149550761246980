.swiper-banner {
  position: relative;
  max-width: 100vw;

  .swiper-wrapper {
    align-items: center;

    .banner-item {
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      a {
        position: relative;
        display: block;
        width: 100%;
      }

      .banner-image {
        width: 100%;
        height: 100%;
        // max-height: 50vw;
        vertical-align: middle;
        object-fit: contain;
      }

      .dapp_banner_item {
        padding: 0px 16px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  // .swiper-pagination {
  //   position: absolute;
  //   bottom: 8px;
  //   right: 12px;
  //   left: auto;
  //   z-index: 2;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   min-width: 33px;
  //   width: auto;
  //   min-height: 19px;
  //   padding: 0 8px;
  //   background: rgba(33, 37, 41, 0.5);
  //   border-radius: 20px;
  //   @include font(10, 600, #dee2e6, 15);

  //   .swiper-pagination-current {
  //     color: #fff !important;
  //   }
  // }
}
