.section-contents {
  position: relative;
  .title {
    padding: 13px 0px;
    @include font(18, 600, $gr00, 27);
  }
  .title_wrap {
    padding: 13px 16px 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .title {
      display: block;
      @include font(18, 600, $default, 22);
      white-space: pre-wrap;
      word-break: break-all;

      // @at-root .page-search & {
      //   padding: 16px 0;
      // }
    }
  }

  > .actions {
    @at-root .page-search & {
      min-height: 54px;
    }
  }

  + .section-contents {
    margin-top: 20px;
  }
}

// 최근 검색
.recently-search {
  .btn-delete-all {
    display: block;
    @include font(14, 600, #ff6258, 21);
    img {
      vertical-align: middle;
    }
  }

  .list-recent-search {
    margin-bottom: -20px;
    display: flex;
    flex-wrap: wrap;
    padding: 12px 20px 12px 20px;
    text-align: center;
    width: 100%;
    > li {
      width: 20.122%;
      margin: 0 2.439% 16px 2.439%;
    }

    .item-recent-search {
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;

      > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      > .text_wrap {
        // display: flex;
        // justify-content: space-between;
        text-align: center;
        @include font(12, 600, $gr00, 18);
        margin-top: 4px;
        .keyword {
          max-width: 68px;
          margin: auto;
          @include multiline-truncate(1);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
      }

      > .btn-delete {
        overflow: hidden;
        display: block;
        margin-left: 8px;
        width: 24px;
        height: 24px;
        text-indent: -9999px;
        background: inline-svg('icon-close-01', $gr00) center center / contain
          no-repeat;
      }
    }
  }
}

.featured_content,
.popular_cats_content {
  padding: 0px 16px 0px 16px;
}
