.page-search {
  background-color: $gr06;
  overflow: hidden;

  .popular_cats_title_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-popular-category {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    > li {
      display: block;
      margin: 4px;
      min-width: calc(50% - 8px);
    }
  }

  .btn-category {
    display: block;
    padding: 12px 0px;
    @include body4($gr01, 600);
    text-align: center;
    background-color: $wt;
    border-radius: 12px;
  }
}
