.page-details {
  overflow: hidden;
  .info-top {
    min-height: 107px;
    padding: 20px 20px 20px 16px;
    border-bottom: 1px solid $gr04;
    // &:after {
    //   content: '';
    //   display: block;
    //   // width: 100vw;
    //   height: 10px;
    //   // margin: 0 0 0 -24px;
    //   background: #f1f3f5;
    // }
    .move_btn_wrap {
      .btn-common {
        @include font(12, 600, $wt, 18);
        min-width: 67px;
        height: 32px;
      }
    }
    .layout-list-options {
      margin: 16px 0 0 0;
      padding: 12px 0 16px;
      border-top: 1px solid #f1f3f5;

      .select-network {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        @include font(14, 600, $default, 18);
      }
    }
    .item-details {
      display: flex;
      justify-content: space-between;
      .item-logo {
        overflow: hidden;
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 100%;
        img {
          width: 60px;
          height: 60px;
        }
      }

      .item-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        width: calc(100% - 48px);
        min-width: 184px;
        .item-info {
          width: calc(100% - 87px);
          margin-right: 12px;
          margin-left: 12px;
          .title_wrap {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .name {
              @include font(18, 600, #212529, 27);
            }
          }
          .token {
            @include font(12, 400, #868e96, 18);
            margin: 4px 0;
          }
          .description {
            @include font(12, 400, #ff6258, 16);
          }
          .bookmark_choice {
            @include font(12, 600, $gr02, 16);
            display: flex;
            overflow: hidden;
            position: relative;
            align-items: center;
            width: 100%;
            margin-top: 2px;
            white-space: nowrap;
            gap: 2px;
            &::after {
              visibility: hidden;
              position: absolute;
              bottom: -6px;
              left: -6px;
              width: 97px;
              height: 6px;
              content: '';
              opacity: 0;
              background-color: #6af298;
              @include transition(opacity 0.5s, visibility 0.5s);
            }
            &[data-type='true'] {
              &::after {
                visibility: visible;
                opacity: 1;
                background-color: #6af298;
                @include transition(opacity 0.5s, visibility 0.5s);
              }
            }

            h3 {
              line-height: 24px;
            }
            .bookmark_bar_wrap {
              width: 100%;
              // .bookmark_bar {
              //   width: 91px;
              //   height: 6px;
              //   border-bottom: 6px solid #6af298;
              // }
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .bookmark {
        width: 40px;
        height: 40px;
        border: solid 1px #dee2e6;
        border-radius: 50%;
      }
      .detail_bookmark {
        width: 24px;
        height: 24px;
      }
    }
  }

  .info-bottom {
    .ytp-large-play-button svg {
      display: none !important;
    }
    .intro_media,
    .intro_media2 {
      position: relative;
      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 91.111%;
        margin: auto;
        content: '';
        border-bottom: 1px solid $gr05;
      }
    }
    // 동영상 1개일 경우
    .intro_media {
      padding: 20px 16px 20px 16px;
      .video-wrap {
        .video-contents {
          display: flex;
          overflow-x: scroll;
          width: 100%;
          &::-webkit-scrollbar {
            display: none;
          }
          .main-video {
            min-width: 100%;
            border-radius: 16px;
            iframe {
              border-radius: 10px;
            }
          }
        }
      }
    }
    // 동영상 2개 이상일 경우
    .intro_media2 {
      padding: 20px 0px 20px 16px;
      .video-wrap {
        .video-contents {
          display: flex;
          overflow-x: scroll;
          width: 100%;
          scroll-snap-type: x mandatory; /* 달라붙는 스냅 속성 부여 */
          &::-webkit-scrollbar {
            display: none;
          }
          .main-video {
            min-width: 87.20999%;
            margin-right: 16px;
            border-radius: 16px;
            scroll-snap-align: start; /* 하위 요소의 정렬 방향 */
            iframe {
              margin-right: 16px;
              border-radius: 10px;
            }
          }
        }
      }
    }
    .intro_event {
      position: relative;
      padding: 20px 16px;
      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 91.111%;
        margin: auto;
        content: '';
        border-bottom: 1px solid $gr05;
      }
    }
    .intro_title {
      position: relative;
      padding: 20px 16px;
    }
    .intro-wrap {
      position: relative;

      h5 {
        @include font(14, 600, $gr00, 21);
        margin: 0 0 12px 0;
      }

      .intro-txt {
        @include font(14, 400, #212529, 21);
      }

      .main-video {
        aspect-ratio: 16 / 9;
      }
    }
  }
}
.notice-wrap {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z('modal') + 1;
  align-items: flex-end;
  width: 100vw;
  animation: fadeIn 0.5s;
  background-color: rgba(#000, 0.4);

  > .notice-contents {
    position: static;
    width: auto;
    min-width: 100%;
    margin: 0;
    animation: show-menu-portal 0.5s $standard-easing;
    animation-iteration-count: 1;
    border-radius: 30px 30px 0px 0px;
    background-color: $wt;
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.03);
    .notice_text_wrap {
      box-sizing: border-box;
      padding: 15px 22.75px 20px 20px;
      > strong {
        @include font(20, 600, #212529, 30);
        display: block;
        text-align: center;
      }

      > ul {
        margin-top: 35px;
        padding: 0px 0px 0px 15px;
        list-style: disc;

        li {
          margin-bottom: 12px;
          @include font(14, 400, #212529, 21);
          list-style: disc;
          white-space: pre-wrap;
          word-break: break-all;

          &.bold {
            @include font(14, 600, #212529, 18);
          }
        }
      }
    }
  }
  .notice-btn-wrap {
    margin: 9px 0px 32px 0px;
    padding: 0px 16px;
    .btn-common {
      min-width: calc(50% - 4px);
      height: 48px;
    }
  }
}

.event-swiper {
  overflow: visible;
  width: 100%;
  margin: 0px 0px 0px 0px;

  .swiper-wrapper {
    .swiper-slide {
      overflow: hidden;
      width: 100%;
      border-radius: 20px;
      img {
        width: 100%;
        // max-height: 50vw;
        vertical-align: middle;
        object-fit: cover;
      }

      .event-txt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        .event-title1 {
          @include font(12, 600, $wt, 18);
        }

        .event-title2 {
          @include font(24, 600, $wt, 36);
        }

        .event-sub-title {
          @include font(10, 600, $wt, 15);
        }
      }

      .event-buttons {
        @include font(12, 600, $wt, 18);
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        right: 16px;
        z-index: 2;
        align-items: center;
        width: 57px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 8px;
        background: #5d44da;

        .icon-buttons {
          &.go {
            width: 12px;
            height: 12px;
            background-image: inline-svg('icon-arrow-01', $wt);
          }
        }
      }
    }
  }

  .swiper-pagination {
    bottom: -20px;
    line-height: 0.4;

    .swiper-pagination-bullet {
      background: #dee2e6;

      &.swiper-pagination-bullet-active {
        background: #5d44da;
      }
    }
  }
}

.event-swiper2 {
  overflow: visible;
  width: 79.268%;
  margin: 0;

  .swiper-wrapper {
    .swiper-slide {
      overflow: hidden;
      position: relative;
      width: 100%;
      border-radius: 20px;

      img {
        width: 100%;
        // max-height: 50vw;
        vertical-align: middle;
        object-fit: cover;
      }

      .event-txt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        .event-title1 {
          @include font(12, 600, $wt, 18);
        }

        .event-title2 {
          @include font(24, 600, $wt, 36);
        }

        .event-sub-title {
          @include font(10, 600, $wt, 15);
        }
      }

      .event-buttons {
        @include font(12, 600, $wt, 18);
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        right: 16px;
        z-index: 2;
        align-items: center;
        width: 57px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 8px;
        background: #5d44da;

        .icon-buttons {
          &.go {
            width: 12px;
            height: 12px;
            background-image: inline-svg('icon-arrow-01', $wt);
          }
        }
      }
    }
  }

  .swiper-pagination {
    bottom: -20px;
    line-height: 0.4;

    .swiper-pagination-bullet {
      background: #dee2e6;

      &.swiper-pagination-bullet-active {
        background: #5d44da;
      }
    }
  }
}
