.page-error {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #F5F5F5;
  white-space: pre-wrap;

  > .inner {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 18vh 20px 0 20px;
  }

  .error-404 {
    display: block;
    text-align: center;

    .title {
      display: block;
      @include heading1(#191919, 600);
    }

    .sub-title {
      display: block;
      @include heading3(#191919, 400);
    }

    .desc {
      display: block;
      margin: 19px 0 54px 0;
      @include body3(#424242, 400);
    }

    .btn-back {
      padding: 12px 30px;
      font-size: 16px;
      font-weight: 500;
      color: #191919;
      border: solid 1px #191919;
      border-radius: 50px;
      background-color: transparent;
    }

    .actions {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      > * + * {
        margin-left: 16px;
      }
    }
  }
}
