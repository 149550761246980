/* variable */
$font-base-stack: ("IBM Plex Sans KR", sans-serif);
$font-special-stack: (
  "Helvetica Neue",
  Helvetica,
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  Arial,
  Dotum,
  "돋움",
  "Apple SD Gothic Neo",
  sans-serif
);
$standard-easing: cubic-bezier(0.4, 0, 0.2, 1);

$break-min: (320px);
$break-tablet: (768px);
$break-desktop: (1280px);
$max-desktop: (1770px);
$min-desktop: (1364px);

$padding-contents: (56px);
$min-contents: (1104px);
$max-contents: (1688px);

$default: (#212529);
$primary: (#5de53a);
