.mainnet-list {
  display: inline-block;

  .icons-mainnet {
    width: 12px;
    height: 12px;
    margin: 0 2px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;

    &:first-child {
      margin-left: 4px;
    }
    &.icons- {
      display: none;
    }
    &.icons-bitcoin {
      background-image: url('../../images/icon_mainnet_bitcoin.svg');
    }

    &.icons-ethereum {
      background-image: url('../../images/icon_mainnet_ethereum.svg');
    }

    &.icons-smartchain {
      background-image: url('../../images/icon_mainnet_smartchain.svg');
    }

    &.icons-klaytn {
      background-image: url('../../images/icon_mainnet_klaytn.svg');
    }

    &.icons-matic {
      background-image: url('../../images/icon_mainnet_polygon.svg');
    }

    &.icons-solana {
      background-image: url('../../images/icon_mainnet_solana.svg');
    }
  }
}

.list-popular {
  overflow: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    position: relative;
    scroll-snap-align: start;
    padding: 0 8px;
    margin-right: 16px;

    li {
      width: 300px;
    }
  }
}

.list-dapps-cards {
  overflow: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-snap-type: x mandatory;
  margin: 0 -20px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > li {
    position: relative;
    scroll-snap-align: start;
    padding: 0 8px;

    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;

      .bookmark-actions {
        right: 36px;
      }
    }

    .bookmark-actions {
      position: absolute;
      right: 20px;
      top: 12px;
      z-index: 3;
      display: block;
      width: 28px;
      height: 28px;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 50%;

      .icon-buttons {
        &.bookmark {
          &::before {
            background-image: inline-svg('icon-bookmark-01', #fff);
          }
        }
      }
    }
  }
}

.list-dapps {
  min-height: calc(100vh - 113px);
  padding-bottom: 56px;
  .rotation_wrap {
    min-height: 64px;
    position: relative;
  }
  .rotation_item {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  > li:last-child {
    border: none;
  }
  > li {
    border-bottom: 1px solid #f1f3f5;

    &.none {
      display: block;
      border: 0;
      @include font(16, 400, $gr01, 20);
      text-align: center;
      padding-top: 160px;

      &::before {
        content: '';
        display: none;
        margin: 0 auto 12px;
        height: 200px;
        width: 200px;
        background: url('/assets/images/nodata@2x.png') center center no-repeat;
        background-size: contain;
      }

      > p {
        @include body4($gr02, 600);
        line-height: inherit;
        > button {
          height: 44px;
          padding: 0 24px;
          margin: 20px 0 0 0;
          border-radius: 16px;
          background: $secondary-green;
          @include body4($wt, 600);
        }
      }
    }

    // # google 검색하기 UI
    //&.none {
    //  margin: 16px 0;
    //
    //  .logo {
    //    padding: 7.2px;
    //    .logo-google {
    //      border-radius: 50px;
    //      display: inline-block;
    //      width: 21.6px;
    //      height: 21.6px;
    //      background-position: center center;
    //      background-repeat: no-repeat;
    //      background-size: contain;
    //      background-image: inline-svg("logo_google");
    //      vertical-align: middle;
    //    }
    //  }
    //
    //  .google-search-navigation {
    //    @include font(14, 600, $default, 21);
    //    margin-left: 10px;
    //    vertical-align: middle;
    //  }
    //}
  }

  &[data-type='round'] {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.03);

    > li {
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.popular_dapp_list {
  .list_item {
    .dapp-item {
      &[data-type='main'] {
        .item-info {
          align-items: center;
          margin-left: 12px;
          width: calc(100% - 44px);
        }
      }
    }
  }
}

.dapp-item {
  cursor: pointer;
  display: block;
  width: 100%;

  figure {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 0;

    .item-wrap {
      display: flex;
      width: calc(100% - 28px);
      overflow: hidden;

      .item-logo {
        overflow: hidden;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        &.dapp-placeholder {
          background-image: inline-svg('dapp_placeholder');
          background-size: contain;
          height: 100%;
          width: 100%;
        }
        img,
        svg {
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      .rank {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        padding: 0px 12px;
        margin-right: -12px;
        @include font(14, 600, $gr00, 21);
      }

      .item-info {
        width: 100%;
        margin-left: 8px;

        .info {
          .name-wrap {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .recommend {
            display: inline-block;
            background-color: #ff6f26;
            border-radius: 60px;
            padding: 1px 6px;
            @include font(10, 600, $wt, 15);
            margin-right: 4px;
          }

          .name {
            @include font(14, 600, $default, 21);
          }

          .token {
            display: block;
            @include font(12, 400, $gr01, 16);
          }

          .description {
            // @include font(12, 400, $gr01, 16);
            overflow: hidden;
            display: block !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 2px;
          }

          .rank_description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 2px;
            @include font(12, 400, $gr01, 16);
            display: block !important;
          }

          .amount {
            display: block;
            @include font(14, 600, $default, 18);
          }
          .rate {
            display: block;
            @include font(12, 600, #ff6258, 16);

            &[data-down='true'] {
              color: #1bbef2;
            }
          }
        }
      }
    }
  }

  &[data-type='main'] {
    figure {
      padding: 0 0 12px 0;

      .item-wrap {
        .item-logo {
          min-width: 40px;
          width: 40px;
        }

        .item-info {
          align-items: center;
          margin-left: 12px;
          width: calc(100% - 24px);

          .info {
            width: 100%;
            width: calc(100% - 24px);

            p {
              &.value {
                align-items: flex-end;
              }
            }
            span {
              @include font(12, 400, $gr01, 16);
            }
            .description {
              @include font(12, 400, $gr01, 16);
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .actions {
        margin-left: 24px;
        display: flex;
        align-items: center;
      }
      .icon-buttons {
        &.bookmark {
          &::before {
            background: transparent;
            background-image: inline-svg(
              'icon_favorite_none_01',
              $gr03,
              transparent
            );
          }
        }
      }
    }
  }

  &[data-type='list'] {
    figure {
      padding: 12px 0;

      .item-wrap {
        .item-logo {
          min-width: 40px;
          width: 40px;
        }

        .item-info {
          align-items: center;
          margin-left: 12px;
          width: calc(100% - 42px);

          .info {
            width: calc(100% - 24px);
            p {
              &.value {
                align-items: flex-end;
              }
            }
            span {
              @include font(12, 400, $gr01, 16);
            }
            .description {
              @include font(12, 400, $gr01, 16);
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
      }
      .icon-buttons {
        &.bookmark {
          &::before {
            background-image: inline-svg(
              'icon_favorite_none_01',
              $gr03,
              transparent
            );
          }
        }
      }
    }
  }

  &[data-type='banner'] {
    figure {
      padding: 12px 0;

      .item-wrap {
        .item-logo {
          min-width: 40px;
          width: 40px;
        }

        .item-info {
          align-items: center;
          margin-left: 12px;
          width: calc(100% - 42px);

          .info {
            width: 100%;
            width: calc(100% - 24px);

            p {
              &.value {
                align-items: flex-end;
              }
            }
            strong {
              @include font(14, 600, $wt, 21);
            }
            span {
              @include font(12, 400, $wt, 16);
            }
            .description {
              @include font(12, 400, $wt, 16);
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
      }
      .icon-buttons {
        &.bookmark {
          &::before {
            background-image: inline-svg('icon_favorite_none_02');
          }
        }
      }
    }
  }

  &[data-type='card'] {
    overflow: hidden;
    height: 200px;
    min-width: 264px;
    max-width: 264px;
    width: 264px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;

    figure {
      position: relative;
      flex-direction: column;
      height: 100%;

      .item-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      > .item-info {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        min-height: 64px;
        padding: 12px 20px;
        background: rgba(0, 0, 0, 0.24);

        .item-logo {
          min-height: 40px;
          height: 40px;
          min-width: 40px;
          width: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          display: block;
          margin-left: 12px;

          .name {
            color: #fff;
          }

          .token {
            display: none;
          }

          .description {
            @include font(12, 400, #fff, 16);
            max-width: 148px;
          }

          .value {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 4px;
            width: 100%;
          }

          .amount {
            min-width: 56px;
            color: #fff;
            text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
          }
          .rate {
            min-width: 46px;
            color: #ffd7d5;
            text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
            &[data-down='true'] {
              color: #ade8fa;
            }
          }
        }

        .go-actions {
          button {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            background-image: inline-svg('icon-arrow-01', #fff);
          }
        }
      }
    }
  }
}

.my_dapp_container {
  .dapp-item {
    max-width: 68px;
    h3 {
      @include font(12, 600, $gr00, 18);
    }
  }
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
  }
  .item-logo {
    overflow: hidden;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: inline-block;
  }
}
