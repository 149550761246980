.icon-buttons {
  display: inline-block;
  height: 32px;
  width: 32px;
  text-indent: -9999px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.back {
    background-image: inline-svg('icon-back-01', $default);
  }

  &.search {
    background-image: inline-svg('icon-search-01', $gr00);
  }

  &.bookmark {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::before {
      background-image: inline-svg('icon_favorite_none_01', $default, $wt);
    }
    &::after {
      opacity: 0;
      visibility: hidden;
      background-image: inline-svg('icon_favorite_added_01', $default);
      @include transition(opacity 0.5s, visibility 0.5s);
    }

    &[value='true'] {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  // design_v1.5 디테일 북마크 버튼 변경
  &.detail_bookmark {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::before {
      background-image: inline-svg('icon_favorite_none_details', $gr02, $wt);
    }
    &::after {
      opacity: 0;
      visibility: hidden;
      background-image: inline-svg('icon_favorite_none_details', $gr02);
      @include transition(opacity 0.5s, visibility 0.5s);
    }
    &[value='true'] {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  // button {
  //   // + button {
  //   //   margin: 0 0 0 8px;
  //   // }
  // }
}

.btn-common {
  border-radius: 12px;
  background: $primary-light-purple;
  @include font(16, 400, #fff, 24);

  &.purple {
    background: $primary-purple;
    color: #fff;
  }

  &.light-purple {
    background: $primary-light-purple;
    color: #fff;
  }

  &.wt {
    background: #fff;
    border: solid 1px $primary-purple;
    color: $primary-purple;
  }
}
