// my_dapp_list 시작
.my_dapp_container {
  display: flex;
  gap: 16px;
  position: relative;
  .add_my_dapp_button {
    text-align: center;
  }
  .rotation_wrap {
    min-width: 68px;
    min-height: 82px;
    position: relative;
  }
  .rotation_item {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  li {
    scroll-snap-align: start; /* 하위 요소의 정렬 방향 */
    min-width: 68px;
    text-align: center;
    position: relative;
    img {
      width: 60px;
      height: 60px;
    }
    h3 {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 600;
    }
    .recommend {
      background-color: $secondary-orange;
      border-radius: 60px;
      padding: 1px 6px;
      @include font(10, 600, $wt, 15);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

// mydapp 가이드
.mydapp_guide {
  position: absolute;
  z-index: z('gnb') + 1;
  animation: fadeIn 1s;
  left: 30px;
  top: 148px;
  &.hide {
    opacity: 0;
    visibility: hidden;
    @include transition(opacity 0.5s, visibility 0.5s 0.5s);
  }

  > .mydapp_message {
    position: relative;
    display: block;
    padding: 8px 12px;
    max-width: 183px;
    @include font(12, 400, #ffffff, 16);
    border-radius: 12px;
    background: linear-gradient(
      106.58deg,
      #b760b0 3.16%,
      #825cf9 53.2%,
      #518eee 97.83%
    );
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.04);

    &::before {
      content: '';
      position: absolute;
      top: -11px;
      left: 17px;
      width: 12px;
      height: 12px;
      background: url('/assets/images/icon_message_arrow_01@2x.png') right
        bottom no-repeat;
      background-size: 100%;
      transform: rotate(90deg);
      border-radius: 2px;
    }
  }
}
