.layout-navigation-header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: z("gnb");
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 12px;
  min-height: 60px;
  height: 60px;
  @include transition(background-color 0.3s $standard-easing);

  &.default {
    background-color: $wt;
  }

  > .navigation-title {
    flex: 1 1 33.333%;
    display: block;
    //width: 100%;
    @include font(16, 500, $default, 20);
    text-align: center;
    opacity: 0;
    @include transition(transform 0.5s 0.3s $standard-easing);
    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
    > strong {
      font-weight: 600;
    }

    &.on {
      position: relative;
      padding: 0;
      opacity: 1;
      text-align: left;
      @include heading3($gr00, 600);
    }
  }

  > .navigition-button {
    flex: 1 1 33.333%;
    display: none;
    text-align: right;
    @include transition(transform 0.5s 0.3s $standard-easing);
    .btn-common {
      @include font(12, 600, #fff, 18);
      padding: 7px 12px;
    }
    &.on {
      display: block;
    }
  }

  &.details {
    &.wt {
      background: #fff;
    }
    > .bookmark {
      opacity: 0;

      &::before {
        background-image: inline-svg("icon-bookmark-01", $gr02);
      }
    }
  }

  .left-actions,
  .right-actions {
    position: relative;
    z-index: z("default");
    display: flex;
    align-items: center;
  }

  // .left-actions {
  //   margin-right: 12px;
  //   margin-left: -10px;
  // }
  .details-left-actions {
    flex: 1 1 33.333%;
  }
  .right-actions {
    .header-icons {
      display: flex;

      .bookmark {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          top: auto;
          bottom: auto;
          right: auto;
          left: auto;
          width: 14px;
          height: 19px;
          background-image: inline-svg("icon-bookmark-02", #212529);
        }

        &::after {
          display: none;
        }
      }
    }
  }

  &[data-invert="true"] {
    > .navigation-title {
      opacity: 1;
    }

    > .navigition-button {
      display: block;
    }

    &.details {
      > .bookmark {
        opacity: 1;
      }
    }
  }

  &[data-invertable="false"] {
    background-color: #fff;
  }
}
