.guide-search {
  position: fixed;
  top: 60px;
  right: 12px;
  z-index: z("gnb") + 1;
  animation: fadeIn 1s;

  &.hide {
    opacity: 0;
    visibility: hidden;
    @include transition(opacity 0.5s, visibility 0.5s 0.5s);
  }

  > .message {
    position: relative;
    display: block;
    padding: 10px 12px;
    max-width: 196px;
    @include font(12, 400, #ffffff, 16);
    border-radius: 12px;
    background: linear-gradient(
      106.58deg,
      #b760b0 3.16%,
      #825cf9 53.2%,
      #518eee 97.83%
    );
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.04);

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      right: 17px;
      width: 12px;
      height: 12px;
      background: url("/assets/images/icon_message_arrow_01@2x.png") right
        bottom no-repeat;
      background-size: 100%;
    }
  }
}
