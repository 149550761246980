.sub_banner_wrap {
  padding: 20px 0px 28px 0px;
  position: relative;
  &::before {
    width: 91.111%;
    content: '';
    border-bottom: 1px solid $gr05;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .sub_banner_container {
    overflow: hidden;
    min-width: 91.111%;
    border-radius: 16px;
    margin: 0px 4.444%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      // max-height: 50vw;
      vertical-align: middle;
      object-fit: contain;
    }
  }
  .dapp_banner_item {
    padding: 0px 16px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(29, 11, 11, 0) 100%
    );
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
