.view_all_header {
  padding: 14px 16px;
  align-items: center;
  .left-actions {
    position: absolute;
  }
  .title_wrap {
    width: 100%;
    .title {
      text-align: center;
      @include font(18, 600, $gr00, 27);
    }
  }
}

.page-view_all {
  background-color: $gr06;
  padding: 0px 16px 0px 16px;
  .list-dapps {
    border-radius: 0;
    box-shadow: none;
    background-color: $gr06;
  }
}
