.searchfield {
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 12px;
  width: 100%;
  margin-left: 8px;

  > form > input[type="search"] {
    padding: 8px 4px 8px 40px;
    width: 100%;
    @include font(16, 400, $default, 26);
    text-align: left;
    background: #f9f9fc inline-svg("icon-search-01", $default) 8px 50% no-repeat;
    background-size: 28px 28px;

    @include placeholder {
      color: $gr03;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      appearance: none;
    }
  }

  .actions {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: z("default");
    margin-top: -10px;
    background-size: #0f0;
  }

  .btn-action {
    display: block;
    height: 20px;
    width: 20px;
    text-indent: -9999px;
    border-radius: 100%;
    background: inline-svg("icon_close_01", $wt,$wt);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
