$ns: bdui !default;

.select-control {
  display: inline-block;
  font-size: 0em;
  max-width: 100%;

  .#{$ns}__control {
    min-height: 0px;
    padding: 0;
    max-width: 100%;
    border: 0px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: unset;

    &:hover {
      cursor: pointer;
      z-index: z("default");
    }

    .#{$ns}__value-container {
      overflow: visible;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      padding: 0;

      .#{$ns}__placeholder {
        position: static;
        margin: 0;
        @include font(14, 400, #ffffff, 18);
        transform: none;
      }

      .#{$ns}__input-container {
        order: 0;
        margin: 0;
        @include font(14, 400, #ffffff, 18);
      }

      .#{$ns}__single-value {
        @include multiline-truncate(1);
        position: static;
        margin: 0;
        max-width: 100%;
        @include font(14, 600, #5D44DA, 18);
        white-space: normal;
      }
    }

    .#{$ns}__indicators {
      margin-left: 4px;
      .#{$ns}__indicator-separator {
        display: none;
      }

      .#{$ns}__indicator {
        display: flex;
        padding: 0;
        height: 20px;
        min-width: 20px;
        width: 20px;

        svg {
          display: none;
        }
      }

      .#{$ns}__dropdown-indicator {
        background-image: inline-svg("icon-arrow-01", #868E96);
        background: inline-svg("icon-arrow-01", #868E96) center no-repeat;
        //transform: rotateX(180deg);
      }
    }

    &--menu-is-open {
      .#{$ns}__indicators {
        .#{$ns}__dropdown-indicator {
          transform: rotateX(0deg);
        }
      }
    }
  }

  // block
  &.block {
    display: block;
  }
}

@keyframes show-menu-portal {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.#{$ns}__menu-portal {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: z("modal") + 1 !important;
  display: flex;
  align-items: flex-end;
  width: 100vw !important;
  background-color: rgba(#000, 0.4);
  animation: fadeIn 0.5s;

  .#{$ns}__menu {
    position: static;
    margin: 0;
    padding: 20px;
    min-width: 100%;
    width: auto;
    border-radius: 30px 30px 0px 0px;
    background-color: #ffffff;
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.03);
    animation: show-menu-portal 0.5s $standard-easing;
    animation-iteration-count: 1;

    .menu-title {
      display: block;
      @include font(20, 600, #000000, 24);

      + .#{$ns}__menu-list {
        margin-top: 16px;
      }
    }

    .#{$ns}__menu-list {
      max-height: 50vh;

      .#{$ns}__option {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 6px 0;
        padding: 14px 36px 14px 12px;
        @include font(16, 400, #000000, 20);
        border-radius: 16px;

        &--is-focused {
          background-color: #f1f0f9;
        }

        &--is-selected {
          color: #5d44da;
          background-color: #f1f0f9;

          &::after {
            content: "";
            position: absolute;
            top: calc(50% - 12px);
            right: 12px;
            display: block;
            height: 24px;
            width: 24px;
            background: inline-svg("icon-check-01", #5d44da);
            background-size: contain;
          }
        }
      }
    }

    .#{$ns}__group {
      padding: 0px;

      .btn-group-heading {
        position: relative;
        display: block;
        padding: 20px 12px;
        width: 100%;
        text-align: left;
        &::after {
          content: "";
          position: absolute;
          top: calc(50% - 10px);
          right: 0;
          display: block;
          height: 20px;
          width: 20px;
          background: inline-svg("icon_arrow_01", $default) center center
          no-repeat;
          background-size: contain;
          transform: rotateX(180deg);
          @include transition(transform 0.3s);
        }

        + div {
          overflow: auto;
          overflow-x: hidden;
          display: none;
          margin: 10px 0;
          padding: 10px 20px;
          max-height: 290px;
          border-radius: 20px;
          background-color: #f1f0f9;
        }

        &[data-expand="true"] {
          &::after {
            transform: rotateX(0deg);
          }
          + div {
            display: block;
          }
        }
      }
      .#{$ns}__group-heading {
        margin: 0;
        padding: 0;
        @include font(16, 400, #000000, 20);
      }

      .#{$ns}__option {
        margin: 0;
        padding: 17px 0 17px 12px;
      }
    }
  }

  .button-container {
    margin-top: 20px;

    .btn-cancel {
      display: block;
      padding: 14px 0;
      width: 100%;
      @include font(16, 400, #ffffff, 20);
      border-radius: 16px;
      background-color: #8475ef;
    }
  }
}
