//category_dapp_title 시작
.category_dapp_title {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  .title {
    display: flex;
    align-items: center;
    h2 {
      margin-left: 4px;
      @include font(18, 600, $gr00, 21);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.view_all {
  display: flex;
  button {
    @include font(14, 600, $gr02, 21);
    img {
      vertical-align: middle;
      line-height: 21px;
    }
  }
}
